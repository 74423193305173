import React from "react";
import { Document, Page, View, Text, Font, Image } from "@react-pdf/renderer";
import styles from "./styles";
import { convertMilliSecondsToTimeString, getDateTime } from "utils/datetime";
import moment from "moment";
import config from "config";

type Props = {
  data?: any;
  userName?: string;
  date?: any;
  sessionList?: any;
  chartList: any[];
};

const GenerateSessionReport = ({ sessionList, userName, chartList }: Props) => {
  const chunkSubstr = (str: any, size: any) => {
    const numChunks = Math.ceil(str.length / size);
    const chunks = new Array(numChunks);

    for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
      chunks[i] = str.substr(o, size);
    }

    return chunks;
  };

  Font.registerHyphenationCallback((word: any) => {
    if (word.length > 10) {
      return chunkSubstr(word, 8);
    } else {
      return [word];
    }
  });
  const renderUserDetail = () => {
    return (
      <View style={styles.userContainer}>
        <View style={styles.dateContainer}>
          <View>
            <Text
              style={styles.userName}
            >{`Session Report for ${userName}`}</Text>
            <Text style={styles.date}>{`Date: ${moment().format(
              "YYYY, MMMM DD"
            )}`}</Text>
          </View>
          <Image src={config.logoIcon} style={styles.pdfLogoContainer} />
        </View>
      </View>
    );
  };
  const renderSessionRow = (list: any) => {
    return list.map((item: any) => {
      return (
        <View
          style={{
            width: "100%",
          }}
        >
          <View
            style={{
              ...styles.horizontalSeperator,
              backgroundColor: "#CFD3DA",
            }}
          />
          <View style={styles.tableHeaderContainer}>
            <View style={{ ...styles.cellContainer, flex: 1.5 }}>
              <Text
                style={{
                  ...styles.rowText,
                  marginLeft: "10px",
                }}
              >
                {item?.sessionName || "-"}
              </Text>
            </View>
            <View style={styles.cellContainer}>
              <Text style={styles.rowText}>
                {getDateTime(item.sessionTime)}
              </Text>
            </View>
            <View style={styles.cellContainer}>
              <Text style={styles.rowText}>
                {convertMilliSecondsToTimeString(item?.duration)}
              </Text>
            </View>
          </View>
        </View>
      );
    });
  };
  const renderSessionList = (list: any) => {
    return (
      <View
        style={{
          ...styles.userDetailBorder,
          marginTop: "40px",
          border: "1px solid #CFD3DA",
        }}
      >
        <View style={styles.tableHeaderContainer}>
          <View style={{ ...styles.cellContainer, flex: 1.5 }}>
            <Text
              style={{
                ...styles.headerText,
                fontSize: "10px",
                marginLeft: "10px",
                textAlign: "left",
              }}
            >
              EXERCISE NAME
            </Text>
          </View>
          <View style={styles.cellContainer}>
            <Text
              style={{
                ...styles.headerText,
                fontSize: "10px",
                textAlign: "left",
              }}
            >
              TIME
            </Text>
          </View>
          <View style={styles.cellContainer}>
            <Text
              style={{
                ...styles.headerText,
                fontSize: "10px",
                textAlign: "left",
              }}
            >
              DURATION
            </Text>
          </View>
        </View>
        {renderSessionRow(list)}
      </View>
    );
  };

  const renderMultipleSessionPage = () => {
    const chunkSize = 15;
    let list = [];
    const newSessionList = sessionList.slice(15, sessionList.length);

    for (let i = 0; i < newSessionList.length; i += chunkSize) {
      list.push(newSessionList.slice(i, i + chunkSize));
    }
    return list.map((item: any) => {
      if (item.length > 0) {
        return (
          <Page size="A4" style={styles.page}>
            <View style={{ ...styles.page, marginTop: "25px" }}>
              {renderSessionList(item)}
            </View>
          </Page>
        );
      }
    });
  };

  const firstSessionList =
    sessionList.length > 0 ? sessionList.slice(0, 15) : [];
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={{ ...styles.page, marginTop: "25px" }}>
          {renderUserDetail()}
          {renderSessionList(firstSessionList)}
        </View>
      </Page>
      {renderMultipleSessionPage()}
      {chartList.map((item: any, index) => {
        return (
          <Page size="A4" style={styles.page} key={index + ""}>
            <View style={styles.chartContainer}>
              <Image source={item} style={styles.chartImage} />
            </View>
          </Page>
        );
      })}
    </Document>
  );
};

export default GenerateSessionReport;
